import "@hotwired/turbo-rails";
import { StreamActions } from "@hotwired/turbo";
require("@rails/activestorage").start();
require("channels");
import ahoy from "ahoy.js";
import "media-chrome";

// src/application.js
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";
const application = Application.start();
const context = require.context("./controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

import { stripeCard } from "app/stripe-card";
import { setup as buyerDashboardSetup } from "app/buyer-dashboard-search";
import {
  setup as chosenSetup,
  teardown as chosenTeardown,
} from "app/chosen-setup";
import { setup as formSubmitSetup } from "app/form-submit-safety";
import { setup as directUploadSetup } from "app/form-file-direct-upload";
import { setup as chartkickSetup } from "app/chartkick-setup";
import { setup as faqToggleSetup } from "app/faq-toggle";
import { setup as exitIntent } from "app/exit-intent";
import { Track } from "app/track";

if (typeof window !== "undefined") {
  window.ahoy = ahoy;
  window.Track = Track;
}

import "../css/application.scss";

import {
  setBasePath,
  SlAlert,
  SlProgressBar,
  SlAnimation,
  SlButton,
  SlCopyButton,
  SlSelect,
  SlIcon,
  SlDropdown,
  SlCheckbox,
  SlInput,
  SlIconButton,
  SlDetails,
  SlDialog,
  SlDrawer,
  SlTooltip,
  SlFormatBytes,
  SlRelativeTime,
} from "@shoelace-style/shoelace";
import "@shoelace-style/shoelace/dist/themes/light";

const rootUrl = document.currentScript.src.replace(/\/packs.*$/, "");
setBasePath(rootUrl + "/packs/js/");

const allSetup = () => {
  $(function () {
    stripeCard();
    buyerDashboardSetup();
    chosenSetup();
    formSubmitSetup();
    directUploadSetup();
    chartkickSetup();
    faqToggleSetup();
    exitIntent();
    window.uetq = window.uetq || [];
    const pagePath = window.location.pathname + window.location.search;
    window.uetq.push("event", "page_view", { page_path: pagePath });
  });
  // wtf -- bug with sl-select
  setTimeout(() => {
    const els = document.querySelectorAll("sl-select");
    els.forEach((el) => {
      el.value = el.getAttribute("value");
    });
  });
};

document.addEventListener("turbo:before-cache", function () {
  chosenTeardown();
});

/* need to setup chosen and things on a 400 error, so
 * we have 2 listeners to fire allSetup
 * https://github.com/hotwired/turbo/issues/85
 * */
document.addEventListener("turbo:render", allSetup);
document.addEventListener("turbo:load", allSetup);

document.addEventListener("turbo:before-render", function (event) {
  event.detail.newBody.querySelectorAll("noscript").forEach((e) => {
    e.remove();
  });
});

StreamActions.ps_redirect = function () {
  const target = this.getAttribute("target");
  window.location.replace(target);
};

StreamActions.ps_refresh_if_matching = function () {
  const target = this.getAttribute("target");
  console.log("refreshing...", target, window.location.pathname);
  if (target && target === window.location.pathname) {
    window.location.reload();
  }
};

require("trix");
require("@rails/actiontext");
